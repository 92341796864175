<template>
  <div class="row" v-if="userInfoLoaded">
    <div class="col-md-6 ml-auto mr-auto">
      <card>
        <card type="user">
          <p class="card-text"></p>
          <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div class="block block-three"></div>
            <div class="block block-four"></div>
            <h2>{{$t('users.workersPermit')}}</h2>
            <h4>{{ companyModel.name }} kt: {{ companyModel.identificationNumber }}</h4>
            <a @click="onAvatarClick">
              <img class="avatar" :src="'data:image/png;base64,' + model.base64ImageData" alt="..." />
              <h5 class="title">{{ model.fullName }}</h5>
              <h6 class="title">{{ model.identificationNumber }}</h6>
            </a>
            <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" />
            <p class="description">
              <a :href="'mailto:' + model.email">{{ model.email }}</a>

            </p>
            <p class="description">
              <a :href="'tel:' + model.phoneNumber">{{ model.phoneNumber }}</a>
            </p>
            <p class="description">
              <a>{{ model.employeeTitle }}</a>
            </p>
          </div>
          <template v-slot:footer v-if="!enableEdit">
            <base-button class="col-md-12 ml-auto mr-auto" type="standard" @click="edit" fill>{{$t('users.editProfile')}}</base-button>
          </template>
        </card>
        <card v-if="enableEdit">
          <h5 slot="header" class="title">{{$t('users.editProfile')}}</h5>
          <div class="row">
            <div class="col-md-8 pr-md-1">
              <base-input
                :label="$t('users.company')"
                :placeholder="$t('users.company')"
                v-model="model.company"
                disabled
              >
              </base-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <base-input
                :label="$t('users.identificationNumber')"
                v-model="companyModel.identificationNumber"
                :placeholder="$t('users.identificationNumber')"
                disabled
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 pr-md-1">
              <base-input
                :label="$t('users.employeeTitle')"
                :placeholder="$t('users.employeeTitle')"
                v-model="model.employeeTitle"
              >
              </base-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <base-input
                :label="$t('users.identificationNumber')"
                v-model="model.identificationNumber"
                :placeholder="$t('users.identificationNumber')"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 pr-md-1">
              <base-input
                :label="$t('users.email')"
                type="email"
                :placeholder="$t('users.email')"
                v-model="model.email"
                disabled
              >
              </base-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <base-input
                :label="$t('users.phoneNumber')"
                v-model="model.phoneNumber"
                :placeholder="$t('users.phoneNumber')"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('users.firstName')"
                v-model="model.firstName"
                :placeholder="$t('users.firstName')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('users.lastName')"
                v-model="model.lastName"
                :placeholder="$t('users.lastName')"
              >
              </base-input>
            </div>
          </div>

          <label>{{$t('users.language')}}</label>
          <div class="row" v-if="model.language">
            <div class="col-md-6 pr-md-1">
              <el-select :class="selectClass" v-model="languageOption" :placeholder="$t('users.selectLanguage')">
                <el-option
                  :class="selectClass"
                  v-for="(info, code) in languageMap"
                  :key="code"
                  :label="`${info.flag} ${info.name}`"
                  :value="code"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row" v-if="userRole !== 'User'">
            <div class="col-md-6 pr-md-1 d-flex">
              <base-checkbox v-model="model.receiveAdminEmails"><p>{{$t('users.adminEmails')}}</p></base-checkbox>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <base-button slot="footer" type="standard" @click="handleUserUpdate" fill>{{$t('common.save')}}</base-button>
          </div>
        </card>
      </card>
    </div>
  </div>
</template>
<script>
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import api from "../../services/api"
import { Select, Option } from 'element-ui';
import { EventBus } from '../../components/event-bus.js';
import i18n from '../../i18n';
import imageCompression from 'browser-image-compression';
import { BaseCheckbox } from 'src/components/index';
import { defaultUserImage } from '../../components/Image Bytes/imagebytes'




export default {
  components: {
    BaseCheckbox,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  data() {
    return {
      model: {
        company: "",
        email: "",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        fullName: "",
        language: "",
        base64ImageData: "",
        identificationNumber: null,
        employeeTitle: null,
        receiveAdminEmails: false
      },
      companyModel: {
        name: "",
        identificationNumber: "",
        base64ImageData: "",
      },
      userInfoLoaded: false,
      languageMap: {
        'en-US': { name: 'English', flag: '🇺🇸' },
        'is-IS': { name: 'Íslenska', flag: '🇮🇸' },
        'pl-PL': { name: 'Polski', flag: '🇵🇱' },
        'lt-LT': { name: 'Lietuvis', flag: '🇱🇹' }
      },
      languageOption: '',
      enableEdit: false,
      userRole: '',
      userId: '',
      currentUserId: ''
    };
  },
  created() {
    this.fetchUserData();
    this.fetchCustomerData();
  },
  methods: {
    fetchUserData() {
      const decodedToken = jwtDecode(Cookies.get('accessToken'));
      const CompanyName = `${decodedToken.CustomerName}`;
      this.userRole = `${decodedToken.role}`;
      this.currentUserId = decodedToken.UserIdentifier;
      this.userId = this.$route.params.userId ? this.$route.params.userId : decodedToken.UserIdentifier;

      let userUrl = `/User/${this.userId}`
      api.get(userUrl)
        .then(response => {
          this.model.company = CompanyName;
          this.model.email = response.data.email;
          this.model.phoneNumber = response.data.phoneNumber;
          this.model.firstName = response.data.firstName;
          this.model.fullName = response.data.firstName + " " + response.data.lastName;
          this.model.lastName = response.data.lastName;
          this.model.language = response.data.language;
          this.model.identificationNumber = response.data.identificationNumber;
          this.model.employeeTitle = response.data.employeeTitle;
          this.model.base64ImageData = response.data.userImageThumbnailBytes !== null ? response.data.userImageThumbnailBytes: defaultUserImage
          // We need to store the initial option in a separate value. Using model.language will not trigger rerender for some reason
          this.languageOption = this.model.language;

          this.model.receiveAdminEmails = response.data.receiveAdminEmails;

          this.userInfoLoaded = true;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    fetchCustomerData() {
      let customerUrl = `/Customers/Me`
      api.get(customerUrl)
        .then(response => {
          this.companyModel.name = response.data.value.name;
          this.companyModel.identificationNumber = response.data.value.identificationNumber;
          this.companyModel.base64ImageData = response.data.value.logoBytes;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    getLanguageInfo(code) {
      return this.languageMap[code];
    },
    edit() {
      this.enableEdit = true;
    },
    handleUserUpdate() {
      let userUrl = `/User/${this.userId}`
      this.model.language = this.languageOption;
      api.put(userUrl, this.model)
        .then(response => {
          // Need to reset all values after the update so the card is correctly updated
          this.model.email = response.data.email;
          this.model.phoneNumber = response.data.phoneNumber;
          this.model.firstName = response.data.firstName;
          this.model.fullName = response.data.firstName + " " + response.data.lastName;
          this.model.lastName = response.data.lastName;
          this.model.language = response.data.language;
          this.model.identificationNumber = response.data.identificationNumber;
          this.model.employeeTitle = response.data.employeeTitle;
          this.model.base64ImageData = response.data.userImageThumbnailBytes !== null ? response.data.userImageThumbnailBytes: defaultUserImage

          if(this.userId === this.currentUserId) {
            Cookies.set('locale',response.data.language,{ secure: true, httpOnly: false })
            this.$i18n.locale = this.model.language;
          }

          this.$notify({
            message: this.$t('users.updated'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.enableEdit = false;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    onAvatarClick() {
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      // Compress the image
      let options = {
        maxSizeMB: 0.5,          // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1920,  // (default: undefined)
        useWebWorker: true,      // (default: true)
      };
      let file = await imageCompression(event.target.files[0], options);
      if(file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.model.base64ImageData = btoa(binary);
          let userUrl = `/User/${this.userId}`
          api.put(userUrl, { userImageBytes: this.model.base64ImageData })
            .then(response => {
              this.$notify({
                message: this.$t('users.imageUpdated'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });
              if (this.userId === this.currentUserId) {
                EventBus.$emit('userImageUpdated', this.model.base64ImageData);
              }
            })
            .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: this.$t('common.unableToUpdate'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
            });
        };
        reader.readAsArrayBuffer(file);
      }
    },
  }
};
</script>
<style></style>
