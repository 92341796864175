<template>
  <div>
    <div v-if="loading">Loading...</div>
    <vue-markdown v-else :source="content"></vue-markdown>
  </div>
</template>

<script>
import axios from 'axios';
import VueMarkdown from 'vue-markdown';

export default {
  components: {
    VueMarkdown
  },
  props: ['page'],
  data() {
    return {
      content: '',
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const mdFile = `./documentation/Introduction.md`;
    const response = await axios.get(mdFile);
    this.$nextTick(() => {
      this.content = response.data;
      this.loading = false;
    });
  },
  watch: {
    page: {
      immediate: true,
      handler(newValue) {
        this.loading = true;
        const mdFile = `./documentation/${newValue}.md`;
        axios.get(mdFile).then(response => {
          this.content = response.data;
          this.loading = false;
        }).catch(error => {
          console.error('Error loading markdown file:', error);
          this.loading = false;
        });
      }
    }
  }
};
</script>